import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { MoveDeConfirmationDialogComponent } from '../../../shared/notification-dialog/move-de-confirmation-dialog/move-de-confirmation-dialog.component';
import { UpdateEmailComponent } from '../../../shared/update-email/update-email.component';
import { CssClass, Roles } from '../../constants/constant';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit {
  readonly myMatchOptions: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'exact',
    paths: 'exact',
    fragment: 'exact',
  };
  public userInformation!: any;
  public navigationList: any[] = [];
  public isNavSideOpen: boolean = false;
  public role: string;
  public roles: any = Roles;
  public activeUrl = '';
  public showParticipantMenu: boolean = false;
  public b2bAccountIds: any = [];
  public activeB2BAccountId = "";
  @Input() usedForMirrorParticipant: any;
  public userId: any;
  public isDecisionEnabled: boolean = false;

  constructor(public authService: AuthService, public router: Router, public dialog: MatDialog, public commonService: CommonService, private activatedRoute: ActivatedRoute,private elementRef: ElementRef) {
    this.participantUserIdAccepted();
    this.userInformation = this.authService.getUserNameObject();
    this.role = this.authService.getRoles()[0];
    this.b2bAccountIds = this.commonService.getB2BAccountIdList();
    this.activeB2BAccountId = this.commonService.getActiveB2BAccountdId() || "";
    this.isDecisionEnabled = this.commonService.getDecisionEnabledFlag();
    if (!this.activeB2BAccountId && this.b2bAccountIds?.length > 0) {
      this.activeB2BAccountId = this.b2bAccountIds[0].clientId;
      this.commonService.setActiveB2BAccountId(this.b2bAccountIds[0].clientId);
    }
    this.changeUrlSet();

    this.router.events.subscribe((event: any) => {
      event.url;
      this.commonService.setActiveUrl(event.url);
    });
  }

  ngOnInit(): void {
    this.getNavigationList();
    this.addClickListener();
  }

  public participantUserIdAccepted() {
    this.commonService.userIdInfo$.subscribe((response) => {
      this.userId = response;
      this.getNavigationList();

    });
  }

  public logOut() {
    this.authService.logout();
  }

  public accountSettings() {
    this.router.navigateByUrl('participant/settings');
  }

  public getNavigationList() {

    switch (this.role) {
      case Roles.tpmaster:
      case Roles.tpadmin:
        if (!this.usedForMirrorParticipant) {
          this.navigationList = [
            {
              title: 'Home',
              url: '/admin/dashboard',
              matchUrl: '/admin/dashboard',
              icon: './app/assets/icons/home_icon.svg',
              isActive: false
            },
            {
              title: 'Carriers',
              url: '/admin/carriers',
              matchUrl: '/admin/carrier',
              icon: './app/assets/icons/implementation_menu_icon.svg',
              isActive: false
            },
            {
              title: 'Clients',
              url: '/admin/clients',
              matchUrl: '/admin/client',
              icon: './app/assets/icons/implementation_menu_icon.svg',
              isActive: false
            },
            {
              title: 'Participants',
              url: '/admin/participants',
              matchUrl: '/admin/participant',
              icon: './app/assets/icons/implementation_menu_icon.svg',
              isActive: false
            },
            {
              title: 'Reports',
              url: '/admin/reports',
              matchUrl: '/admin/report',
              icon: './app/assets/icons/report_menu_icon.svg',
              isActive: false
            },
            {
              title: 'Admin Settings',
              url: '/admin/admin-settings',
              matchUrl: '/admin/admin-setting',
              icon: './app/assets/icons/implementation_menu_icon.svg',
              isActive: false
            },
          ];
        }
        else {
          this.participantMenuShow();
          this.navigationList = [
            {
              title: 'Home',
              url: '/participant-mirror/dashboard/' + this.userId,
              matchUrl: '/participant-mirror/dashboard/' + this.userId,
              icon: './app/assets/icons/home_icon.svg',
              isActive: false
            },
            {
              title: 'Elections',
              url: '/participant-mirror/elections/' + this.userId,
              matchUrl: '/participant-mirror/elections/' + this.userId,
              icon: './app/assets/icons/set-up-later.svg',
              isActive: false
            },
            {
              title: 'Communications',
              url: '/participant-mirror/communications/' + this.userId,
              matchUrl: '/participant-mirror/communications/' + this.userId,
              icon: './app/assets/icons/support_menu_icon.svg',
              isActive: false
            },
            {
              title: 'Documents',
              url: '/participant-mirror/documents/' + this.userId,
              matchUrl: '/participant-mirror/documents/' + this.userId,
              icon: './app/assets/icons/support_menu_icon.svg',
              isActive: false
            }];
        }
        break;
      case Roles.clientmaster:
      case Roles.clientadmin:
      case Roles.clientreporting:
        this.navigationList = [
          {
            title: 'Home',
            url: '/client/dashboard',
            matchUrl: '/client/dashboard',
            icon: './app/assets/icons/home_icon.svg',
            isActive: false
          },
          {
            title: 'Participants',
            url: '/client/participants',
            matchUrl: '/client/participant',
            icon: './app/assets/icons/implementation_menu_icon.svg',
            isActive: false
          },
          {
            title: 'Reports',
            url: '/client/reports',
            matchUrl: '/client/report',
            icon: './app/assets/icons/report_menu_icon.svg',
            isActive: false
          },
          {
            title: 'Subsidies', 
            url: '/client/subsidies',
            matchUrl: '/client/subsidies',
            icon: './app/assets/icons/report_menu_icon.svg',
            isActive: false,
          },
          // {
          //   title: 'Client Configurations', 
          //   url: 'Client_Configurations',
          //   matchUrl: '',
          //   icon: './app/assets/icons/report_menu_icon.svg',
          //   isActive: false,
          //   submenu: [
          //     {
          //       title: 'Subsidies',
          //       url: '/client/subsidies',
          //       matchUrl: '/client/subsidies',
          //       isActive: false
          //     }
          //   ]
          // }
        ];
        break;
      case Roles.participant:
        this.participantMenuShow();
        this.navigationList = [
          {
            title: 'Home',
            url: '/participant/dashboard',
            matchUrl: '/participant/dashboard',
            icon: './app/assets/icons/home_icon.svg',
            isActive: false
          },
          {
            title: 'Elections',
            url: '/participant/elections',
            matchUrl: '/participant/election',
            icon: './app/assets/icons/set-up-later.svg',
            isActive: false
          },
          {
            title: 'Communications',
            url: '/participant/communications',
            matchUrl: '/participant/communication',
            icon: './app/assets/icons/support_menu_icon.svg',
            isActive: false
          },
          {
            title: 'Documents',
            url: '/participant/documents',
            matchUrl: '/participant/document',
            icon: './app/assets/icons/support_menu_icon.svg',
            isActive: false
          }];
        break;
      default:
        this.navigationList = [];
        break;
    }

    this.setActiveUrl();
  }
  toggleSubMenu(item: any): void {
    item.showSubMenu = !item.showSubMenu;
  }
  addClickListener(): void {
    document.body.addEventListener('click', (event) => {
      const clickedElement = event.target as HTMLElement;
      const submenu = this.elementRef.nativeElement.querySelector('.submenu');
      const isSubmenuClicked = submenu?.contains(clickedElement);
      const isMenuClicked = this.elementRef.nativeElement.contains(clickedElement);
      const isTitleBarClicked = clickedElement.closest('.title-bar');
      const isArrowClicked = clickedElement.classList.contains('arrow-icon') || clickedElement.parentElement?.classList.contains('arrow-icon');
      
      if(isArrowClicked){
        const menuItemTitle = clickedElement.getAttribute('alt')?.toString().trim();
        this.closeSubMenu(menuItemTitle == null || undefined ? undefined : menuItemTitle.toString());
      }
      else if (isMenuClicked) {
        const menuItemTitle = clickedElement.innerHTML.toString().trim();
        this.closeSubMenu(menuItemTitle == null || undefined ? undefined : menuItemTitle.toString());
      } 
      else {
        this.closeSubMenu();
      }
    });
  }
  
  closeSubMenu(excludeTitle?: string): void {
    // Logic to close the submenu
    this.navigationList.forEach(item => {
      if (!excludeTitle ||  !excludeTitle.toLocaleLowerCase().includes(item.title.toLocaleLowerCase())) {
        item.showSubMenu = false;
      }
    });
  }
  
  public navigate(url: string | null) {
    if (url) {
      this.router.navigateByUrl(url);
      this.commonService.setActiveUrl(url);
    }
  }

  public changeEmail() {
    const dialogRef = this.dialog.open(UpdateEmailComponent, {
      panelClass: CssClass.advanceFullScreenClass,
      backdropClass: CssClass.backDropClass
    });
  }

  public openSideNavBar(event: any = null) {
    this.isNavSideOpen = !this.isNavSideOpen;
    event.stopPropogation();
    event.preventDefault();
  }

  public checkURL(activeUrl: any) {

    activeUrl;
  }

  public setActiveUrl() {
    var activeUrl = this.commonService.getActiveUrl();
    this.navigationList.map((item: any) => {
      if (activeUrl && activeUrl.startsWith(item.matchUrl)) {
        item.isActive = true;
      } else if (item.submenu) {
        let found = false;
        item.submenu.forEach((subItem: any) => {
          if (activeUrl && activeUrl.startsWith(subItem.matchUrl)) {
            found = true;
          }
        });
        if(found){
          item.isActive = true; 
        }
        else{
          item.isActive = false; 
        }

      } else {
        item.isActive = false;
      }
    });
  }
  

  public changeUrlSet() {
    this.commonService.activeUrl$.subscribe((response) => {
      this.navigationList.map((item: any) => {
        if (response && response.startsWith(item.matchUrl)) {
          item.isActive = true;
        } else if (item.submenu) {
          let found = false;
          item.submenu.forEach((subItem: any) => {
            if (response && response.startsWith(subItem.matchUrl)) {
              found = true;            
            }
          });
          if(found){
            item.isActive = true; 
          }
          else{
            item.isActive = false; 
          }
        } else {
          item.isActive = false;
        }
      });
    });
  }
  

  public participantMenuShow() {
    this.commonService.isParticipantMenuShow$.subscribe((response) => {
      this.showParticipantMenu = response;
    });
  }

  public onClientChange() {
    this.commonService.setActiveB2BAccountId(this.activeB2BAccountId);
    window.location.reload();
    console.log("Active Id:", this.activeB2BAccountId);
  }

  //public redirectToDE() {
  //  this.commonService.redirectToDE();
  //}
}


