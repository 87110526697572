import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
import { MoveDeConfirmationDialogComponent } from '../../shared/notification-dialog/move-de-confirmation-dialog/move-de-confirmation-dialog.component';
import { TopBannerComponent } from '../components/top-banner/top-banner.component';
import { CssClass, Roles } from '../constants/constant';
import { ConfigurationService } from './configuration.service';
import { LoaderService } from './loader.service';
import { UserIdleConfig, UserIdleService } from './user-idle.service';
//import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public clientUrl: string = '';

  public userInfo = new BehaviorSubject<any>({});
  public userInfo$ = this.userInfo.asObservable();

  public userSelectedPlans = new BehaviorSubject<any>([]);
  public userSelectedPlans$ = this.userSelectedPlans.asObservable();

  public userAddressInfo = new BehaviorSubject<any>([]);
  public userAddressInfo$ = this.userAddressInfo.asObservable();

  public userIdInfo = new BehaviorSubject<any>([]);
  public userIdInfo$ = this.userIdInfo.asObservable();

  public userWaivedPlans = new BehaviorSubject<any>([]);
  public userWaivedPlans$ = this.userSelectedPlans.asObservable();

  public activeUrl = new BehaviorSubject<any>([]);
  public activeUrl$ = this.activeUrl.asObservable();

  public dueDate = new BehaviorSubject<any>([]);
  public dueDate$ = this.dueDate.asObservable();

  public tokenInfo = new BehaviorSubject<any>({});
  public tokenInfo$ = this.tokenInfo.asObservable();

  public isParticipantMenuShow = new BehaviorSubject<any>([]);
  public isParticipantMenuShow$ = this.isParticipantMenuShow.asObservable();
  public idleTime = 1800; //unit is 'seconds'
  public logoutTime = 60; //unit is 'seconds'
  public snackbarOpened: boolean = false;

  public isCookieAccepted = new BehaviorSubject<any>([]);
  public isCookieAccepted$ = this.isCookieAccepted.asObservable();

  public b2bAccountIdList = new BehaviorSubject<any>([]);
  public b2bAccountIdList$ = this.b2bAccountIdList.asObservable();

  public activeB2BAccountId = new BehaviorSubject<any>([]);
  public activeB2BAccountId$ = this.activeB2BAccountId.asObservable();

  public cumulativeDiff = new BehaviorSubject<any>({});
  public cumulativeDiff$ = this.cumulativeDiff.asObservable();

  public isDecisionEnabled = new BehaviorSubject<any>([]);
  public isDecisionEnabled$ = this.isDecisionEnabled.asObservable();

  constructor(private snackBar: MatSnackBar, private router: Router, private userIdleService: UserIdleService, private msalService: MsalService, private loaderService: LoaderService, private dialog: MatDialog, private configService: ConfigurationService) { }

  public getMatDialogOptions(data: any = null, panelClass: string = '', disableClose: boolean = false): any {
    let matDialogOptions = MatDialogOptions;
    if (data !== null)
      matDialogOptions.data = data;

    matDialogOptions.panelClass = panelClass;
    matDialogOptions.disableClose = disableClose;
    return matDialogOptions;
  }

  public downloadFile(file: any) {
    const nav = (window.navigator as any);
    if (file && file.name && (file.base64 || file.byteArray)) {
      if (nav.msSaveOrOpenBlob) {
        const byteCharacters = atob(file.base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "data:application/octet-stream;base64" });

        nav.msSaveOrOpenBlob(blob, file.name);
      }
      else {
        var fileContent = file.base64 ? file.base64 : file.byteArray;
        const element = document.createElement('a');
        element.setAttribute('href', 'data:application/octet-stream;base64,' + fileContent);
        element.setAttribute('download', file.name);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
    else {
      var fileContent = file.base64 ? file.base64 : file.byteArray;
      const element = document.createElement('a');
      element.setAttribute('href', 'data:application/octet-stream;base64,' + fileContent);
      element.setAttribute('download', file.name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  }

  public showTopBanner(message: string, buttonText: string = '', timeOut: number = 6000, actionToPerform: { (): void } | null = null, showSupportMail: boolean = false) {
    let snackbarRef = this.snackBar.openFromComponent(TopBannerComponent, {
      data: {
        message: message,
        actionButtonText: buttonText,
        showSupportMail: showSupportMail
      }, duration: timeOut,
      panelClass: 'top-banner-default',
      verticalPosition: 'top', // 'top' | 'bottom'
      horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
    });

    if (actionToPerform) {
      snackbarRef.afterDismissed().subscribe((action: any) => {
        if (action.dismissedByAction)
          actionToPerform();
      });
    }
  }

  public setUserInfo(response: any) {
    this.userInfo.next(response);
  }

  public getUserInfo(): any {
    return this.userInfo.value;
  }

  public getMomentDate(dateValue: Date, format: string = 'YYYY-MM-DD') {
    const momentDate = moment(dateValue, format);
    return momentDate.isValid() ? momentDate.format(format) : null;
  }

  public getMomentDateDiff(dateValueFirst: Date,dateValueSecond: Date, format: string = 'YYYY-MM-DD') {
    const momentDate1 = moment(dateValueFirst, format);
    const momentDate2 = moment(dateValueSecond, format);
    return  momentDate1.diff(momentDate2, 'days', true); 
  }

  public getMomentDateWithTheValue(dateValue: Date, convertFormat: string = 'MM-DD-YYYY', format: string = 'YYYY-MM-DD') {
    const momentDate = moment(dateValue, format);
    return momentDate.isValid() ? momentDate.format(convertFormat) : null;
  }

  /**
    * Utility Method: Returns a deep copy of the object
    */
  public deepCopy(oldObj: any) {
    var newObj = oldObj;
    if (oldObj && typeof oldObj === "object") {
      if (oldObj instanceof Date) {
        return new Date(oldObj.getTime());
      }
      newObj = Object.prototype.toString.call(oldObj) === "[object Array]" ? [] : {};
      for (var i in oldObj) {
        newObj[i] = this.deepCopy(oldObj[i]);
      }
    }
    return newObj;
  }

  public generateUUID() {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function')
      d += performance.now();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public setSelectedPlan(response: any) {
    this.userSelectedPlans = response;
  }

  public getSelectedPlan(): any {
    return this.userSelectedPlans;
  }

  public setUserId(response: any) {
    if (response)
      this.userIdInfo.next(response);
  }

  public getUserId(): any {
    return this.userIdInfo.value;
  }

  public setUserAddress(response: any) {
    if (response)
      this.userAddressInfo.next(response);
  }

  public getUserAddress(): any {
    return this.userAddressInfo.value;
  }

  public setWaivedPlan(response: any) {
    this.userWaivedPlans = response;
  }

  public getWaivedPlan(): any {
    return this.userWaivedPlans;
  }

  public redirectToUrl(url: string) {
    var roles = this.getUserInfo().roles;
    if (roles.indexOf(Roles.tpmaster) > -1 || roles.indexOf(Roles.tpadmin) > -1) {
      this.router.navigateByUrl('admin/' + url);
    }
    else if (roles.indexOf(Roles.clientmaster) > -1 || roles.indexOf(Roles.clientadmin) > -1 || roles.indexOf(Roles.clientreporting) > -1) {
      this.router.navigateByUrl('client/' + url);
    }
    else if (roles.indexOf(Roles.participant) > -1) {
      this.router.navigateByUrl('participant/' + url);
    }
  }

  public downloadFiles(files: any) {
    for (let i = 0; i < files.length; i++) {
      this.downloadFile(files[i]);
    }
  }

  public calculateAge(birthdate: any): number {
    return moment().diff(birthdate, 'years');
  }

  public calculateAgeFromDate(birthdate: any, fromDate: any): number {
    birthdate = moment(birthdate);
    fromDate = moment(fromDate);
    return fromDate.diff(birthdate, 'years');
  }

  public setActiveUrl(response: any) {
    if (response)
      this.activeUrl.next(response);
  }

  public getActiveUrl(): any {
    return this.activeUrl.value;
  }

  public setDueDate(response: any) {
    this.dueDate.next(response);
  }

  public getDueDate(): any {
    return this.dueDate.value;
  }

  public manageDependentAccordingToCoverage(selectedCoverage: any, dependents: any) {
    switch (selectedCoverage) {
      case 'Retiree 65+':
      case 'Single Only':
        dependents.map((dependent: any) => dependent.isSelected = false);
        break;
      case 'Single + 1':
        var count = 0;
        dependents.map((dependent: any) => {
          if (count == 0 && dependent.isSelected)
            count++;
          else
            dependent.isSelected = false;
        });
        break;

      case 'Surviving Spouse':
      case 'Single + Spouse':
      case 'Retiree And Spouse':
        var count = 0;
        dependents.map((dependent: any) => {
          if (dependent.relationship == "Spouse" && dependent.isSelected && count == 0)
            count++;

          else
            dependent.isSelected = false;
        });
        break;

      case 'Single + Child':
        var count = 0;
        dependents.map((dependent: any) => {
          if (dependent.relationship == "Child" && count == 0 && dependent.isSelected)
            count++;
          else
            dependent.isSelected = false;
        });
        break;

      case 'Single + Children':
        dependents.map((dependent: any) => {
          if (dependent.relationship != "Child")
            dependent.isSelected = false;
        });
        break;
      case 'Family':
      case 'Retiree Or Spouse':
        break;
    }

    return dependents;
  }

  public checkAndLogoutUser() {
    if (localStorage.lastEventDate) {
      var lastEventDateStored = localStorage.getItem("lastEventDate");
      var lastEventDate = lastEventDateStored != null ? new Date(lastEventDateStored) : new Date();
      var currentDate = new Date();
      var seconds = (currentDate.getTime() - lastEventDate.getTime()) / 1000;
      if (this.idleTime < seconds) {
        localStorage.setItem("isStopTimer", "false");
        this.logout();
      }
    }
  }

  public setTimerConfigValue() {
    var obj = new UserIdleConfig();
    this.userIdleService.stopWatching();
    obj.idle = this.idleTime;
    obj.timeout = this.logoutTime;
    this.userIdleService.setConfigValues(obj);
  }

  public logout() {
    this.loaderService.startProgressBar();
    this.msalService.logout();
    this.setActiveB2BAccountId("");
  }

  public setUserIdleWatch() {
    this.userIdleService.startWatching();
    this.userIdleService.onTimerStart().subscribe((count: any) => {
      if (count) {
        this.showSnackbarForInactivity();
      }
    });
    this.userIdleService.onTimeout().subscribe(() => {
      this.logout();
    });
  }

  public showSnackbarForInactivity() {
    if (!this.snackbarOpened) {
      this.snackbarOpened = true;
      this.setLogoutAlertSnackbar();
    }
  }

  public setLogoutAlertSnackbar(showSupportMail: boolean = false) {
    let snackBarRef = this.snackBar.openFromComponent(TopBannerComponent, {
      data: {
        message: 'User is going to logout in ' + this.logoutTime + ' seconds. Do you want to stay?',
        actionButtonText: 'Yes',
        showSupportMail: showSupportMail
      },
      duration: (this.logoutTime * 1000),
      panelClass: 'top-banner-default',
      verticalPosition: 'top', // 'top' | 'bottom'
      horizontalPosition: 'center', //'start' | 'center' | 'end' | 'left' | 'right'
    });

    snackBarRef.onAction().subscribe(() => {
      this.userIdleService.stopTimer();
      this.snackbarOpened = false;
    });
  }

  public setIsParticipantMenuShow(response: any) {
    this.isParticipantMenuShow.next(response);
  }

  public getIsParticipantMenuShow(): any {
    return this.isParticipantMenuShow.value;
  }

  public getMomentDateWithTimeZone(dateValue: Date, format: string = 'MM/DD/YYYY') {
    const momentdat = moment.utc(dateValue).local();
    const localtz = moment.tz.guess();
    const date = momentdat.clone().tz(localtz);
    const mydate = moment(date).format(format);
    return momentdat.isValid() ? mydate : null;
  }

  public setTokenInfo(response: any) {
    this.tokenInfo.next(response);
  }

  public getTokenInfo(): any {
    return this.tokenInfo.value;
  }

  public setIsCookieAccepted(response: any) {
    this.isCookieAccepted.next(response);
  }

  public getIsCookieAccepted(): any {
    return this.isCookieAccepted.value;
  }

  public setB2BAccountIdList(response: any) {
    this.b2bAccountIdList.next(response);
  }

  public getB2BAccountIdList() {
    return this.b2bAccountIdList.value;
  }

  public setActiveB2BAccountId(id: any) {
    localStorage.setItem("activeAccountId", id);
  }

  public getActiveB2BAccountdId() {
    return localStorage.getItem("activeAccountId");
  }

  public setDecisionEnabledFlag(response: any) {
    this.isDecisionEnabled.next(response);
  }

  public getDecisionEnabledFlag(): any {
    return this.isDecisionEnabled.value;
  }

  public getNoOfDaysInMonth(date: any) {
    date = moment(date);
    return date.daysInMonth();
  }

  public setCumulativeDiff(response: any) {
      this.cumulativeDiff.next(response);
  }

  public getCumulativeDiff(): any {
    return this.cumulativeDiff.value;
  }

  public redirectToDE() {
    this.dialog.open(MoveDeConfirmationDialogComponent, {
      panelClass: CssClass.smallScreenClass,
      backdropClass: CssClass.backDropClass
    }).afterClosed().subscribe(result => {
      if (result) {
        var url = this.configService.decision.redirectUrl;
        window.open(url, '_blank');
      }
    });
  }
}

export const MatDialogOptions: any = {
  disableClose: false,
  closeOnNavigation: true,
  maxWidth: '100vw',
  height: '100%'
};
